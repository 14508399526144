import { useState, useEffect } from "react";

const useDeliveryFilter = (initialOptions) => {
  const [options, setOptions] = useState(initialOptions);
  const [filters, setFilters] = useState({
    minCost: "",
    maxCost: "",
    minTime: "",
    maxTime: "",
  });

  const filterOptions = () => {
    const filtered = initialOptions.filter((option) => {
      const cost = parseInt(option.cost.replace("₽", "").trim());
      const time = parseInt(option.deliveryTime);

      const isCostInRange =
        (!filters.minCost || cost >= filters.minCost) &&
        (!filters.maxCost || cost <= filters.maxCost);
      const isTimeInRange =
        (!filters.minTime || time >= filters.minTime) &&
        (!filters.maxTime || time <= filters.maxTime);

      return isCostInRange && isTimeInRange;
    });
    setOptions(filtered);
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const resetFilters = () => {
    setFilters({
      minCost: "",
      maxCost: "",
      minTime: "",
      maxTime: "",
    });
    setOptions(initialOptions);
  };

  const sortOptions = (sortField) => {
    const sorted = [...options].sort((a, b) => {
      if (sortField === "cost") {
        return parseInt(a.cost) - parseInt(b.cost);
      } else if (sortField === "time") {
        return parseInt(a.deliveryTime) - parseInt(b.deliveryTime);
      } else if (sortField === "rating") {
        return parseFloat(b.rating) - parseFloat(a.rating);
      }
      return 0;
    });
    setOptions(sorted);
  };

  useEffect(() => {
    filterOptions();
  }, [filters]);

  return { options, sortOptions, handleFilterChange, resetFilters, filters };
};

export default useDeliveryFilter;
