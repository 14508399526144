import React from "react";

const FilterPanel = ({ filters, handleFilterChange, resetFilters }) => (
  <div className="filter-panel">
    <div className="filter-range">
      <label>Цена: </label>
      <input
        type="number"
        placeholder="Мин"
        value={filters.minCost}
        onChange={(e) => handleFilterChange("minCost", e.target.value)}
      />
      <input
        type="number"
        placeholder="Макс"
        value={filters.maxCost}
        onChange={(e) => handleFilterChange("maxCost", e.target.value)}
      />
    </div>
    <div className="filter-range">
      <label>Срок: </label>
      <input
        type="number"
        placeholder="Мин (дней)"
        value={filters.minTime}
        onChange={(e) => handleFilterChange("minTime", e.target.value)}
      />
      <input
        type="number"
        placeholder="Макс (дней)"
        value={filters.maxTime}
        onChange={(e) => handleFilterChange("maxTime", e.target.value)}
      />
    </div>
  </div>
);

export default FilterPanel;
