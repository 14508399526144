import React from "react";
import AsideMenu from "./asideMenu/AsideMenu";
import "./Aside.css";

export const Aside = () => {
  const menuItems = [
    { label: "Перевозка", path: "/shipping" },
    { label: "Доставка", path: "/delivery" },
    { label: "Отслеживание", path: "/tracking" },
  ];

  return (
    <aside>
      <div className="search">
        <input type="text" placeholder="Поиск по сайту" />
      </div>
      <AsideMenu items={menuItems} />
    </aside>
  );
};
