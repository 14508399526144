const InputField = ({
  label,
  id,
  type = "text",
  value,
  onChange,
  placeholder,
  className = "input-container",
  error,
}) => {
  const inputClassName = error ? "input-container-error" : { className };
  return (
    <div className={className}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={inputClassName}
      />
      {error && <span className="error-message-input">{error}</span>}{" "}
    </div>
  );
};

export default InputField;
