import "./MainPage.css";
import { Header } from "../../layout/header/Header";
import { Aside } from "../../layout/aside/Aside";
import Content_main from "./content/Content_main";
function MainPage() {
  return (
    <>
      <Header />
      <div className="wrapper">
        <Aside />
        <Content_main />
      </div>
    </>
  );
}

export default MainPage;
