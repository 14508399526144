import React from "react";

const DeliveryELem = ({ option }) => {
  return (
    <div className="option">
      <div className="company">
        <img
          src={option.logo}
          alt="Логотип компании"
          className="company-logo"
        />
        <div className="company-details">
          <div className="company-name">{option.companyName}</div>
          <div className="service-type">{option.serviceType}</div>
        </div>
      </div>
      <div className="rating-details">
        <span className="rating">{option.rating}★</span>
        <span className="reviews">{option.reviews}</span>
      </div>
      <div className="delivery-type">{option.deliveryType}</div>
      <div className="delivery-time">{option.deliveryTime}</div>
      <div className="cost">{option.cost}</div>
      <div className="select-btn-container">
        <button className="select-btn">Выбрать</button>
      </div>
    </div>
  );
};

export default DeliveryELem;
