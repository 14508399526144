import React from "react";
import SelectField from "../../UI/SelectField";
import InputField from "../../UI/InputField";
import ShippingFormStore from "../../store/ShippingFormStore";
import { observer } from "mobx-react-lite";

const CargoInfoSection = observer(({ title = "Информация о грузе" }) => {
  const { cargoType, cargoVolume, errors } = ShippingFormStore;

  return (
    <>
      <div className="section">
        <h3>{title}</h3>
        <div className="route-selectors">
          <SelectField
            selectorName="cargo-selector"
            label="Тип груза"
            name="Выберите тип груза"
            id="cargo-type-select"
            value={cargoType}
            onChange={(value) => ShippingFormStore.setField("cargoType", value)}
            options={[
              { value: "Документы", label: "Документы" },
              { value: "Груз", label: "Груз" },
            ]}
          />
          {cargoType === "Документы" && (
            <InputField
              label="Вес, кг."
              type="number"
              id="weight-input-docs"
              placeholder="Введите вес"
              value={ShippingFormStore.cargoWeight}
              error={errors.cargoWeight}
              onChange={(value) =>
                ShippingFormStore.setField("cargoWeight", value)
              }
            />
          )}
          {cargoType === "Груз" && (
            <>
              <InputField
                label="Вес, кг."
                type="number"
                id="weight-input-cargo"
                placeholder="Введите вес"
                value={ShippingFormStore.cargoWeight}
                error={errors.cargoWeight}
                onChange={(value) =>
                  ShippingFormStore.setField("cargoWeight", value)
                }
              />
              <InputField
                label="Длина, м"
                type="number"
                id="length-input-cargo"
                placeholder="Введите длину"
                value={ShippingFormStore.cargoLength}
                error={errors.cargoLength}
                onChange={(value) =>
                  ShippingFormStore.setField("cargoLength", value)
                }
              />
              <InputField
                label="Ширина, м"
                type="number"
                id="width-input-cargo"
                placeholder="Введите ширину"
                value={ShippingFormStore.cargoWidth}
                error={errors.cargoWidth}
                onChange={(value) =>
                  ShippingFormStore.setField("cargoWidth", value)
                }
              />
              <InputField
                label="Высота, м"
                type="number"
                id="height-input-cargo"
                placeholder="Введите высоту"
                value={ShippingFormStore.cargoHeight}
                error={errors.cargoHeight}
                onChange={(value) =>
                  ShippingFormStore.setField("cargoHeight", value)
                }
              />
              <InputField
                label="Объем, м³"
                type="text"
                id="volume-display-cargo"
                value={cargoVolume}
                readOnly
              />
            </>
          )}
          <SelectField
            selectorName="delivery-type-selector"
            label="Вид доставки"
            name="Вид доставки"
            id="delivery-type-select"
            value={ShippingFormStore.deliveryType}
            onChange={(value) =>
              ShippingFormStore.setField("deliveryType", value)
            }
            options={[{ value: "Дверь-Дверь", label: "Дверь-Дверь" }]}
          />
        </div>
      </div>
    </>
  );
});

export default CargoInfoSection;
