import React from 'react'
import containers from "../../../public/containers.jpeg"
import "./Content_main.css"
const Content_main = () => {
  return (
    <div className="content">
      <div className="content-block">
      <div className="text">
            <h1>
              Интеллектуальная логистика.
              <br />
              Прозрачные решения.
              <br />
              Быстрая доставка.
            </h1>
            <p>Доставим всё, всегда, везде!</p>
          </div>
          <div className="image">
            <img src={containers} alt="Контейнеры" />
          </div>
      </div>
         
        </div>
  )
}

export default Content_main