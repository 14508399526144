import { makeAutoObservable } from "mobx";

class ShippingFormStore {
  departureCountry = "";
  departureCity = "";
  departureAddress = "";
  destinationCountry = "";
  destinationCity = "";
  destinationAddress = "";
  cargoType = "";
  cargoWeight = "";
  cargoLength = "";
  cargoWidth = "";
  cargoHeight = "";
  cargoVolume = "";
  deliveryType = "";
  errors = {};
  isSubmit = false;

  constructor() {
    makeAutoObservable(this);
  }

  setField(field, value) {
    this[field] = value;
    this.validateField(field);
    
    if (["cargoLength", "cargoWidth", "cargoHeight"].includes(field)) {
      this.calculateVolume();
    }
  }

  validateField(field) {
    let value = this[field];
    if (value === "") {
      delete this.errors[field];
      return;
    }
    if (field === "cargoWeight" && (isNaN(value) || value <= 0)) {
      this.errors[field] = "Вес должен быть положительным числом";
    } else if (
      ["cargoLength", "cargoWidth", "cargoHeight"].includes(field) &&
      (isNaN(value) || value <= 0)
    ) {
      this.errors[field] = "Габариты должны быть положительными числами";
    } else {
      delete this.errors[field];
    }
  }

  calculateVolume() {
    if (
      this.cargoLength > 0 &&
      this.cargoWidth > 0 &&
      this.cargoHeight > 0
    ) {
      this.cargoVolume = (
        this.cargoLength *
        this.cargoWidth *
        this.cargoHeight
      ).toFixed(2);
    } else {
      this.cargoVolume = "";
    }
  }

  resetForm() {
    this.departureCountry = "";
    this.departureCity = "";
    this.departureAddress = "";
    this.destinationCountry = "";
    this.destinationCity = "";
    this.destinationAddress = "";
    this.cargoType = "";
    this.cargoWeight = "";
    this.cargoLength = "";
    this.cargoWidth = "";
    this.cargoHeight = "";
    this.cargoVolume = "";
    this.deliveryType = "";
    this.errors = {};
    this.isSubmit = false; 
  }

  setSubmitStatus(status) {
    this.isSubmit = status; 
  }

  get formData() {
    return {
      departureCountry: this.departureCountry,
      departureCity: this.departureCity,
      departureAddress: this.departureAddress,
      destinationCountry: this.destinationCountry,
      destinationCity: this.destinationCity,
      destinationAddress: this.destinationAddress,
      cargoType: this.cargoType,
      cargoWeight: this.cargoWeight,
      cargoLength: this.cargoLength,
      cargoWidth: this.cargoWidth,
      cargoHeight: this.cargoHeight,
      cargoVolume: this.cargoVolume,
      deliveryType: this.deliveryType,
    };
  }

  hasErrors() {
    return Object.keys(this.errors).length > 0;
  }
}

const shippingFormStoreInstance = new ShippingFormStore();
export default shippingFormStoreInstance;