import React from "react";
import { Link, useLocation } from "react-router-dom";

const AsideMenu = ({ items }) => {
  const location = useLocation();

  return (
    <nav>
      <ul>
        {items.map((item, index) => (
          <li
            key={index}
            className={location.pathname === item.path ? "active" : ""}
          >
            <Link to={item.path}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default AsideMenu;
