export const validateShippingForm = (formData) => {
    const {
      departureCountry,
      departureCity,
      departureAddress,
      destinationCountry,
      destinationCity,
      destinationAddress,
      cargoType,
      cargoWeight,
      cargoLength,
      cargoWidth,
      cargoHeight,
      cargoVolume,
      deliveryType,
    } = formData;
  
    if (
      !departureCountry ||
      !departureCity ||
      !departureAddress ||
      !destinationCountry ||
      !destinationCity ||
      !destinationAddress ||
      !cargoType ||
      !cargoWeight ||
      (cargoType === "Груз" &&
        (!cargoLength || !cargoWidth || !cargoHeight || !cargoVolume)) ||
      !deliveryType
    ) {
      return false; 
    }
  
    return true;
  };
  