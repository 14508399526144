import React from "react";
import SelectField from "../../UI/SelectField";
import InputField from "../../UI/InputField";
import ShippingFormStore from "../../store/ShippingFormStore";
import { observer } from "mobx-react-lite";
import "./DeliveryInfoSection.css";

const DeliveryInfoSection = observer(() => {
  return (
    <>
      <div className="section">
        <div className="route-selectors-info">
          <div className="address-inputs">
            <label className="label-from">Откуда:</label>
            <InputField
              className="input-from"
              label="Адрес"
              id="departure-address"
              type="text"
              placeholder="Введите адрес"
              value={ShippingFormStore.departureAddress}
              onChange={(value) =>
                ShippingFormStore.setField("departureAddress", value)
              }
            />
            <label className="label-where">Куда:</label>
            <InputField
              className="input-where"
              label="Адрес"
              id="destination-address"
              type="text"
              placeholder="Введите адрес"
              value={ShippingFormStore.destinationAddress}
              onChange={(value) =>
                ShippingFormStore.setField("destinationAddress", value)
              }
            />
          </div>
          <div className="cargo-input">
            <SelectField
              selectorName="cargo-selector"
              label={"Тип груза"}
              name={"Выберите тип груза"}
              id="cargo-type-select"
              value={ShippingFormStore.cargoType}
              onChange={(value) =>
                ShippingFormStore.setField("cargoType", value)
              }
              options={[
                { value: "Документы", label: "Документы" },
                { value: "Груз", label: "Груз" },
              ]}
            />
            {ShippingFormStore.cargoType === "Документы" && (
              <InputField
                label="Вес, кг."
                type="number"
                id="weight-input-docs"
                placeholder="Введите вес"
                value={ShippingFormStore.cargoWeight}
                onChange={(value) =>
                  ShippingFormStore.setField("cargoWeight", value)
                }
              />
            )}
            {ShippingFormStore.cargoType === "Груз" && (
              <>
                <InputField
                  className="input-container-label"
                  label="Вес, кг."
                  type="number"
                  id="weight-input-cargo"
                  placeholder="Введите вес"
                  value={ShippingFormStore.cargoWeight}
                  onChange={(value) =>
                    ShippingFormStore.setField("cargoWeight", value)
                  }
                />
                <InputField
                  className="input-container-label"
                  label="Длина, м"
                  type="number"
                  id="volume-input-cargo-length"
                  placeholder="Введите длину"
                  value={ShippingFormStore.cargoLength}
                  onChange={(value) =>
                    ShippingFormStore.setField("cargoLength", value)
                  }
                />
                <InputField
                  className="input-container-label"
                  label="Ширина, м"
                  type="number"
                  id="volume-input-cargo-width"
                  placeholder="Введите ширину"
                  value={ShippingFormStore.cargoWidth}
                  onChange={(value) =>
                    ShippingFormStore.setField("cargoWidth", value)
                  }
                />
                <InputField
                  className="input-container-label"
                  label="Высота, м"
                  type="number"
                  id="volume-input-cargo-height"
                  placeholder="Введите высоту"
                  value={ShippingFormStore.cargoHeight}
                  onChange={(value) =>
                    ShippingFormStore.setField("cargoHeight", value)
                  }
                />
                <InputField
                  className="input-container-label"
                  label="Объем, м³"
                  type="number"
                  id="volume-input-cargo-volume"
                  placeholder="Введите объем"
                  value={ShippingFormStore.cargoVolume}
                  onChange={(value) =>
                    ShippingFormStore.setField("cargoVolume", value)
                  }
                />
              </>
            )}
            <SelectField
              selectorName="delivery-type-input"
              label={"Вид доставки"}
              name={"Вид доставки"}
              id="delivery-type-select"
              value={ShippingFormStore.deliveryType}
              onChange={(value) =>
                ShippingFormStore.setField("deliveryType", value)
              }
              options={[{ value: "Дверь-Дверь", label: "Дверь-Дверь" }]}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default DeliveryInfoSection;
