import React from "react";
import "./DeliveryOptions.css";
import DeliveryELem from "../deliveryElem/DeliveryElem";
import useDeliveryFilter from "../../features/DeliveryFilter";
import FilterPanel from "../filterPanel/FilterPanel";
import psg_logo from "../../public/psg_logo.svg";
import cdek_logo from "../../public/cdek_logo.svg";
const DeliveryOptions = () => {
  const initialOptions = [
    {
      logo: psg_logo,

      companyName: "PSG Logistics",
      serviceType: "Экспресс",
      rating: "4.3/5",
      reviews: "14,356 отзывов",
      deliveryType: "Склад-Склад",
      deliveryTime: "3 рабочих дня",
      cost: "940 ₽",
    },
    {
      logo: cdek_logo,

      companyName: "СДЭК",
      serviceType: "Супер экспресс",
      rating: "4.2/5",
      reviews: "14,356 отзывов",
      deliveryType: "Склад-Склад",
      deliveryTime: "2 рабочих дня",
      cost: "1030 ₽",
    },
  ];

  const { options, sortOptions, handleFilterChange, resetFilters, filters } =
    useDeliveryFilter(initialOptions);

  return (
    <div className="delivery-options">
      <FilterPanel
        filters={filters}
        handleFilterChange={handleFilterChange}
        resetFilters={resetFilters}
      />
      <div className="filter-buttons">
        <button className="select-btn" onClick={() => sortOptions("cost")}>
          По стоимости
        </button>
        <button className="select-btn" onClick={() => sortOptions("time")}>
          По срокам
        </button>
        <button className="select-btn" onClick={() => sortOptions("rating")}>
          По рейтингу
        </button>
        <div className="reset-filter-btn">
          <button className="select-btn" onClick={resetFilters}>
            Сбросить фильтры
          </button>
        </div>
      </div>
      <div className="options-header">
        <div>КОМПАНИЯ</div>
        <div>РЕЙТИНГ</div>
        <div>ДОСТАВКА</div>
        <div>ВРЕМЯ</div>
        <div>СТОИМОСТЬ</div>
      </div>
      {options.length > 0 ? (
        options.map((option, index) => (
          <DeliveryELem key={index} option={option} />
        ))
      ) : (
        <div className="no-options">
          Нет предложений, удовлетворяющих фильтрам
        </div>
      )}
    </div>
  );
};

export default DeliveryOptions;
