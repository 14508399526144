import { React } from "react";
import { observer } from "mobx-react-lite";
import RouteSection from "../../../../components/routeSection/RouteSection";
import CargoInfoSection from "../../../../components/cargoInfoSection/CargoInfoSection";
import Breadcrumb from "../../../../UI/Breadcrumb";
import Button from "../../../../UI/Button";
import ShippingFormStore from "../../../../store/ShippingFormStore";
import { validateShippingForm } from "../../../../features/validateShippingForm";
import "./ShippingForm.css";
const ShippingForm = observer(() => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateShippingForm(ShippingFormStore.formData)) {
      alert("Пожалуйста, заполните все поля.");
      return;
    }
    if (ShippingFormStore.hasErrors()) {
      alert("Пожалуйста, введите корректные данные.");
      return;
    }
    ShippingFormStore.setSubmitStatus(true);
  };

  return (
    <div className="content-ship">
      <Breadcrumb
        links={[
          { href: "/", label: "Главная" },
          { href: "#", label: "Доставка" },
        ]}
      />
      <form className="shipping-form" onSubmit={handleSubmit}>
        <RouteSection />
        <CargoInfoSection />
        <Button text="Посмотреть" type="submit" className="submit-btn" />
      </form>
    </div>
  );
});

export default ShippingForm;
