import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../public/sherpa_logo.svg";
import "./Header.css";

export const Header = () => {
  return (
    <header>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Sherpa Global Logistic" />
        </Link>
      </div>
      <div className="login">
        <button>Войти</button>
      </div>
    </header>
  );
};
