import React from "react";
import RouteBlock from "./routeBlock/RouteBlock";
import ShippingFormStore from "../../store/ShippingFormStore";
import { observer } from "mobx-react-lite";
const RouteSection = observer(() => {
  return (
    <>
      <h2>ОЗНАКОМИТЬСЯ С ПРЕДЛОЖЕНИЯМИ ПО ДОСТАВКЕ</h2>
      <div className="section">
        <h3>Маршрут отправления</h3>
        <div className="route">
          <RouteBlock
            title="Откуда"
            country={ShippingFormStore.departureCountry}
            setCountry={(value) =>
              ShippingFormStore.setField("departureCountry", value)
            }
            city={ShippingFormStore.departureCity}
            setCity={(value) =>
              ShippingFormStore.setField("departureCity", value)
            }
            address={ShippingFormStore.departureAddress}
            setAddress={(value) =>
              ShippingFormStore.setField("departureAddress", value)
            }
          />
          <RouteBlock
            title="Куда"
            country={ShippingFormStore.destinationCountry}
            setCountry={(value) =>
              ShippingFormStore.setField("destinationCountry", value)
            }
            city={ShippingFormStore.destinationCity}
            setCity={(value) =>
              ShippingFormStore.setField("destinationCity", value)
            }
            address={ShippingFormStore.destinationAddress}
            setAddress={(value) =>
              ShippingFormStore.setField("destinationAddress", value)
            }
          />
        </div>
      </div>
    </>
  );
});

export default RouteSection;
