import React from "react";

const Button = ({
  text = "Посмотреть",
  onClick,
  type = "button",
  className = "",
  containerClassName = "",
}) => {
  return (
    <div
      className={
        containerClassName ? `${containerClassName}` : "button-container"
      }
    >
      <button className={className} type={type} onClick={onClick}>
        {text}
      </button>
    </div>
  );
};

export default Button;
