import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainPage from "../pages/main_page/MainPage";
import DeliveryPage from "../pages/delivery/DeliveryPage";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="delivery" element={<DeliveryPage />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
