import SelectField from "../../../UI/SelectField";
import InputField from "../../../UI/InputField";
const RouteBlock = ({
  title,
  country,
  setCountry,
  city,
  setCity,
  address,
  setAddress,
}) => {
  return (
    <div className="route-block">
      <h4>{title}:</h4>
      <div className="route-selectors">
        <SelectField
          name={"Выберите страну"}
          label="Страна"
          id="country-select"
          value={country}
          onChange={setCountry}
          options={[{ value: "Россия", label: "Россия" }]}
        />
        <SelectField
          name={"Выберите город"}
          label="Город"
          id="city-select"
          value={city}
          onChange={setCity}
          options={[{ value: "Казань", label: "Казань" }]}
        />
      </div>
      <InputField
        className="input-container-address"
        label="Адрес"
        id="address-input"
        type="text"
        placeholder="Введите адрес"
        value={address}
        onChange={setAddress}
      />
    </div>
  );
};

export default RouteBlock;
